import React, { useState, useEffect, useCallback } from 'react';
import GroupedExpense from '../../../../forms/groupedExpense';
import Expense from '../../../../forms/expense';
import restApiClient from '../../../../services/restApiClient';
import CrudTable from '../../../../components/crudTable';
import FilterTree from '../../../../helpers/filterTree';
import {
  Container,
  Preview,
  ErrorContainer,
  ImportCsvButton,
  Import,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  StyledMoneyInput,
  StyledSelectServiceSupplierDialogControl,
  StyledSelectPaymentTypeDialogControl,
  StyledKeyboardDatePicker,
  StyledMuiPickersUtilsProvider,
  Payments,
  PlaceholderContainer,
} from './styles';
import moment from 'moment';
import ImportCardCsvDialog from '../../../../components/importCardCsvDialog';
import SelectBulkPaydayDialog from '../../../../components/selectBulkPaydayDialog';
import first from 'lodash/first';
import DayJsUtils from '@date-io/dayjs';
import isEmpty from 'lodash/isEmpty';

function GroupedExpenseList({ history }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useState('');

  const [errors, setErrors] = useState({});
  const [selectedCsv, setSelectedCsv] = useState(null);
  const [openCsvDialog, setOpenCsvDialog] = useState(false);
  const [selectedServiceSupplier, setSelectedServiceSupplier] = useState(null);
  const [selectedPaymentType, setSelectedPaymentType] = useState(null);
  const [showTicketPrice, setShowTicketPrice] = useState(false);
  const [ticketPrice, setTicketPrice] = useState(0);
  const [openPaydayDialog, setOpenPaydayDialog] = useState(false);
  const [groupedExpenseId, setGroupedExpenseId] = useState(false);

  const [selectedDueDate, setSelectedDueDate] = useState(null);

  const columns = GroupedExpense.tableSchema;

  const listCSVs = [
    { value: 'vr', label: 'Vale Refeição' },
    { value: 'vt', label: 'Vale Transporte' },
  ];

  const loadAdverseExpenses = useCallback(() => {
    restApiClient.groupedExpense
      .getAll(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('expenses.employee.user.name', searchBy)
              .or()
              .like('expenses.patient.name', searchBy)
              .or()
              .like('expenses.dentist.user.name', searchBy)
              .or()
              .like('date', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  useEffect(() => {
    loadAdverseExpenses();
  }, [loadAdverseExpenses]);

  return (
    <Container>
      <CrudTable
        title={'Despesas Agrupadas'}
        modelActions={GroupedExpense.actions}
        columns={columns}
        customHeaderComponent={
          <>
            <ImportCsvButton
              type="submit"
              variant="contained"
              color="primary"
              endIcon={<Import />}
              onClick={() => setOpenCsvDialog(true)}
            >
              Importar Despesas
            </ImportCsvButton>
          </>
        }
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          view: {
            icon: <Preview />,
            onClick: (groupedExpenseId) => {
              history.push({
                pathname: `/financial/expenses/grouped/${groupedExpenseId}`,
              });
            },
            title: 'Visualizar Despesas Agrupadas',
            allowedActions: [GroupedExpense.actions.getAll],
          },
          setPayday: {
            icon: <Payments />,
            onClick: (groupedExpenseId) => {
              setOpenPaydayDialog(true);
              setGroupedExpenseId(groupedExpenseId);
            },
            title: 'Pagar Despesas',
            selector: (row) =>
              !isEmpty(row.expenses.filter((x) => x.status === 'Aberta')),
            allowedActions: [[Expense.actions.bulkPayment]],
          },
          delete: {
            onClick: (id, index) => {
              restApiClient.groupedExpense.delete(id).then(() => {
                const result = { ...rows };
                if (index > -1) {
                  result.values.splice(index, 1);
                  result.count--;
                }
                setRows(result);
              });
            },
            selector: (row) => row.expensesCount === 0,
          },
        }}
        customCellRenderers={{
          responsable: {
            renderer: (field, value, column, row) => {
              if (row?.expense?.employeeId) {
                return row?.expense?.employee?.user?.name;
              } else if (row?.expense?.patientId) {
                return row?.expense?.patient?.name;
              } else if (row?.expense?.dentistId) {
                return row?.expense?.dentist?.user?.name;
              } else if (row?.expense?.expensable) {
                return row?.expense?.expensable?.isPhysicalPerson
                  ? row?.expense?.expensable?.socialName
                  : row?.expense?.expensable?.fantasyName;
              } else {
                return '-';
              }
            },
          },
          date: {
            renderer: (index, value) => {
              return moment(value).format('DD/MM/YYYY');
            },
          },
          value: {
            renderer: (field, value) => {
              return value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
        }}
      />

      <SelectBulkPaydayDialog
        open={openPaydayDialog}
        groupedExpenseId={groupedExpenseId}
        onCancel={() => setOpenPaydayDialog(false)}
        onSuccess={() => {
          setOpenPaydayDialog(false);
          loadAdverseExpenses();
        }}
      />

      <ImportCardCsvDialog
        open={openCsvDialog}
        title="Importar Despesas"
        placeholder={
          selectedCsv && (
            <PlaceholderContainer>
              <h3>Colunas Obrigatórias:</h3>
              <ul>
                {selectedCsv === 'vr' && (
                  <>
                    <li>cpf</li>
                    <li>valor</li>
                  </>
                )}
                {selectedCsv === 'vt' && (
                  <>
                    <li>matricula</li>
                    <li>quantidade</li>
                  </>
                )}
              </ul>
            </PlaceholderContainer>
          )
        }
        restSubmitData={async (submitFile) => {
          const newErrors = { ...errors };
          if (!selectedCsv) {
            newErrors['selectedCsv'] = 'Campo obrigatório';
          }
          if (!selectedServiceSupplier) {
            newErrors['serviceSupplierId'] = 'Campo obrigatório';
          }
          if (!selectedPaymentType) {
            newErrors['paymentTypeId'] = 'Campo obrigatório';
          }
          if (!selectedDueDate) {
            newErrors['dueDate'] = 'Campo obrigatório';
          }

          if (ticketPrice < 0.1 && selectedCsv === 'vt') {
            newErrors['ticketPrice'] = 'Campo obrigatório';
          }

          setErrors(newErrors);

          if (
            !selectedServiceSupplier ||
            !selectedPaymentType ||
            !selectedDueDate ||
            !selectedCsv ||
            (ticketPrice < 0.1 && selectedCsv === 'vt')
          ) {
            throw new Error();
          }

          let response;
          switch (selectedCsv) {
            case 'vr':
              response = await restApiClient.expense.submitFoodVoucherModel(
                submitFile,
                selectedServiceSupplier,
                selectedPaymentType,
                selectedDueDate
              );
              break;
            case 'vt':
              response =
                await restApiClient.expense.submitTransportVoucherModel(
                  submitFile,
                  selectedServiceSupplier,
                  selectedPaymentType,
                  selectedDueDate,
                  ticketPrice
                );
              break;
            default:
              throw new Error();
          }
          return response;
        }}
        onError={(error) => {
          if (error?.response?.data?.errors) {
            const newErrors = { ...errors };
            newErrors['ImportCardCsvDialog'] = error.response.data.errors;
            setErrors(newErrors);
          }
        }}
        onClose={() => {
          setOpenCsvDialog(false);
          setErrors({});
          setSelectedServiceSupplier(null);
          setSelectedPaymentType(null);
          setSelectedCsv(null);
          setSelectedDueDate(null);
          setTicketPrice(0);
        }}
        onCancel={() => {
          setOpenCsvDialog(false);
          setErrors({});
          setSelectedServiceSupplier(null);
          setSelectedPaymentType(null);
          setSelectedCsv(null);
          setSelectedDueDate(null);
          setTicketPrice(0);
        }}
        onSuccess={() => {
          loadAdverseExpenses();
          setOpenCsvDialog(false);
          setErrors({});
          setSelectedServiceSupplier(null);
          setSelectedPaymentType(null);
          setSelectedCsv(null);
          setSelectedDueDate(null);
          setTicketPrice(0);
        }}
        customform={
          <>
            <FormControl required size="small" error={!isEmpty(errors)}>
              <InputLabel htmlFor="outlined-selectedUnit-native-simple">
                Selecione um Modelo
              </InputLabel>
              <Select
                value={selectedCsv || ''}
                label="Selecione um Modelo"
                onChange={(e) => {
                  setSelectedCsv(e.target.value);
                  setErrors({});
                  if (e.target.value === 'vt') {
                    setShowTicketPrice(true);
                  } else {
                    setShowTicketPrice(false);
                  }
                }}
              >
                {listCSVs?.map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {!isEmpty(errors) ? 'Modelo ou arquivo inválido' : ''}
              </FormHelperText>
            </FormControl>
            <StyledSelectServiceSupplierDialogControl
              data={selectedServiceSupplier}
              errors={errors['serviceSupplierId']}
              onSuccess={(id) => {
                setSelectedServiceSupplier(id);
                const newErrors = { ...errors };
                delete newErrors['serviceSupplierId'];
                setErrors(newErrors);
              }}
            />
            <StyledSelectPaymentTypeDialogControl
              data={selectedPaymentType}
              errors={errors['paymentTypeId']}
              onSuccess={(id) => {
                setSelectedPaymentType(id);
                const newErrors = { ...errors };
                delete newErrors['paymentTypeId'];
                setErrors(newErrors);
              }}
            />
            <FormControl required size="small" error={!!errors['dueDate']}>
              <StyledMuiPickersUtilsProvider utils={DayJsUtils}>
                <StyledKeyboardDatePicker
                  style={{ width: '100%' }}
                  margin="normal"
                  id="date-from"
                  size="small"
                  label="Data de vencimento"
                  format="DD/MM/YYYY"
                  value={selectedDueDate}
                  onChange={(date) => {
                    const newErrors = { ...errors };
                    delete newErrors['dueDate'];
                    setErrors(newErrors);
                    setSelectedDueDate(
                      moment(date.toDate()).format('YYYY-MM-DD')
                    );
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'Editar data',
                  }}
                  cancelLabel="Cancelar"
                  okLabel="Selecionar"
                  invalidDateMessage="Data em formato inválido."
                />
              </StyledMuiPickersUtilsProvider>
              <FormHelperText>{errors['dueDate'] ?? ''}</FormHelperText>
            </FormControl>
            {showTicketPrice ? (
              <StyledMoneyInput
                value={ticketPrice}
                onChange={(e, value) => {
                  const newErrors = { ...errors };
                  delete newErrors['ticketPrice'];
                  setErrors(newErrors);
                  setTicketPrice(value);
                }}
                size="small"
                label="Preço unitário da passagem"
                errors={errors['ticketPrice']}
                helperText={errors['ticketPrice'] ?? ''}
              />
            ) : null}
          </>
        }
        errorMessage={
          errors['ImportCardCsvDialog'] && (
            <ErrorContainer>
              <ul>
                {Object.keys(errors['ImportCardCsvDialog']).map((key) => {
                  return Object.keys(errors['ImportCardCsvDialog'][key]).map(
                    (key2) => {
                      return (
                        <li key={key}>{`Linha ${key}: ${key2} - ${first(
                          errors['ImportCardCsvDialog'][key][key2]
                        )}`}</li>
                      );
                    }
                  );
                })}
              </ul>
            </ErrorContainer>
          )
        }
      />
    </Container>
  );
}

export default GroupedExpenseList;
